@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../fonts/Montserrat-Italic-VariableFont_wght.ttf") format("ttf");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../fonts/Montserrat-VariableFont_wght.ttf") format("ttf");
}

:root {
  --primary-color: #438a7a;
  --body-bg-color: #f5f5f5;
  --text-primary-color: #0f0f12;
  --text-secondary-color: rgba(15, 15, 18, 0.75);
  --button-text-color: #fff;
  --icon-color: #438a7a;

  --in-card-spacer-y: 1rem;
  --in-card-spacer-x: 1rem;
}

body {
  background-color: var(--body-bg-color) !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--text-primary-color);
  line-height: 1.2;
}

.h5,
h5 {
  font-size: 1.1725rem !important;
}

label,
a,
p {
  font-size: 16px !important;
}

.text-muted {
  color: var(--text-secondary-color) !important;
}

.text-muted span {
  font-size: 16px !important;
}

/* select {
  -webkit-appearance: listbox !important;
}

textarea {
  box-shadow: none !important;
  outline: none !important;
}

textarea:focus {
  border: 1px solid var(--primary-color) !important;
}

input[type="checkbox"] {
  background-color: #fff !important;
  cursor: pointer;
  border: 1px solid #438a7a !important;
}

input:focus {
  box-shadow: none !important;
  outline: none !important;
} */

.btn-primary {
  background-color: var(--primary-color) !important;
  color: var(--button-text-color) !important;
  font-size: 15.008px !important;
  line-height: 22.512px !important;
  padding: 6px 14.4px !important;
  border: transparent !important;
}

a {
  color: var(--primary-color);
  text-decoration: none !important;
}

/* .form-check-input:checked {
  background-color: #438a7a !important;
  border-color: #438a7a !important;
} */

.text_primary {
  color: var(--primary-color) !important;
}

.pointer {
  cursor: pointer;
}

.change_pas_type {
  position: absolute;
  right: 10px;
  top: 39px;
  z-index: 999;
}

.overlay-container {
  position: relative;
}

.color-overlay {
  --in-bg-opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(var(--primary-color), var(--in-bg-opacity)) !important;
}

.bg-light {
  background-color: #f5f5f5 !important;
}

.alert_warning {
  color: #664c1d;
  background-color: #fff2db;
  border-color: #ffe5b6;
}

@media (min-width: 992px) {
  .carousel-inner {
    position: absolute !important;
    bottom: 0 !important;
  }
}

.div-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000005a;
}

.carousel-inner h5 {
  font-size: 1.25rem !important;
}

.search-widget-icon {
  color: #76797e;
  font-size: 16px;
  left: 11px;
  position: absolute;
  top: 12px;
  z-index: 10;
}

.search_icon {
  color: #76797e;
  font-size: 16px;
  left: 11px;
  position: absolute;
  top: 12px;
  z-index: 10;
}

.user-name-text {
  color: var(--text-primary-color);
}

@media (min-width: 767px) {
  .user-name-text {
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .user-name-text {
    padding: 5px 2px;
  }
}

svg {
  color: var(--icon-color) !important;
}

.dropdown-item {
  color: #0f0f12 !important;
  font-size: 15px !important;
}

.dropdown-item i,
.dropdown-item svg {
  font-size: 14px !important;
}

.popover_item {
  position: absolute;
  right: 4px;
  top: 60px;
}

.popover_item1 {
  position: absolute;
  right: 31px;
  top: 22px;
}

.notifi_num {
  position: absolute;
  left: 22px;
  bottom: 20px;
  color: white;
  background: red;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  text-align: center;
  font-size: 13px;
}

.menu-title {
  color: #919da9 !important;
  cursor: default;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.menu-title span {
  display: inline-block;
  padding: 12px 20px;
  color: #919da9 !important;
}

.navbar-nav .nav-sm .nav-link {
  color: #6c6f73 !important;
  white-space: none;
  cursor: pointer;
}

/* .navbar-nav .nav-link.active {
  background: linear-gradient(90deg, rgba(66, 138, 121, 0.1) 30%, transparent);
  color: #438a7a !important;
} */

.menus-list {
  color: var(--text-primary-color) !important;
}

.menuActive {
  background: linear-gradient(90deg, rgba(66, 138, 121, 0.1) 30%, transparent);
  color: #438a7a !important;
}

.menuActive .child_dot:before {
  background-color: #438a7a;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link a:hover,
.navbar-nav .nav-link span:hover {
  color: #438a7a !important;
}

.navbar-nav .nav-link.active span,
.navbar-nav .nav-link.active i,
.navbar-nav .nav-link.active svg {
  color: #438a7a !important;
}

.navbar-nav .nav-link i {
  display: inline-block;
  font-size: 20px;
  line-height: inherit;
  min-width: 1.85rem;
}

.navbar-nav .nav-link {
  align-items: center;
  color: #5d6064;
  color: var(--in-vertical-menu-item-color);
  display: flex;
  font-family: DM Sans, sans-serif;
  font-size: 0.969rem;
  padding: 0.531rem 1.5rem;
}

.nav-link i,
.nav-link svg {
  font-size: 20px !important;
}

.navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  color: #919da9;
  content: ">";
  display: block;
  font-size: 0.813rem;
  font-weight: 900;
  margin-left: auto;
  position: absolute;
  right: 20px;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.menuActive:before {
  background-color: #438a7a;
  border-radius: 0 3px 3px 0;
  content: "";
  height: 30px;
  position: absolute;
  width: 3px;
  left: 0;
}

.child_dot:before {
  border: 1px solid #6c6f73;
  border-radius: 50%;
  content: "";
  height: 7px;
  transition: all 0.4s ease-in-out;
  width: 7px;
  margin-right: 12px;
  margin-left: 30px;
}

.menus-list {
  width: 100%;
}

.menus-list span {
  font-size: 14px !important;
}

.child_icon i,
.child_icon svg {
  font-size: 12px !important;
}

.page-title-box .h4,
.page-title-box h4 {
  font-size: 18px !important;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.card {
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
  border: none !important;
}

.btn {
  box-shadow: none;
}

.card-header {
  background-color: #fff !important;
}

.card-title {
  font-size: 18px;
  margin: 0 0 7px;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-22 {
  font-size: 22px;
}

.fs-17 {
  font-size: 17px;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.grafh_pop {
  position: absolute;
  right: 34px;
  top: 40px;
}

.dropdown-menu {
  border: none !important;
  box-shadow: 1px 1px 4px 1px #c1c1c1 !important;
}

.dropdown-item:active,
.dropdown-item:hover {
  background-color: #f8f9fa !important;
}

@media (min-width: 992px) {
  .mini-widget {
    border-right: 1px solid #e9ebec !important;
  }
}

@media (max-width: 991px) {
  .mini-widget {
    border-bottom: 1px solid #e9ebec !important;
    margin-bottom: 15px;
  }
}

.text_overview span {
  font-size: 0.704rem !important;
}

.mini-widget:last-child {
  border: none !important;
}

.custom-vector-map svg path {
  fill: #f5f5f5 !important;
}

.text-truncate span {
  font-size: 0.704rem !important;
}

.table th {
  font-size: 14px !important;
}

.table p,
.table td {
  color: #0f0f12 !important;
}

.card-header {
  padding: 1rem !important;
}

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.dropdown_btn {
  background-color: #477bf92b;
  color: #fff;
  height: 20px;
  align-items: center;
  display: flex;
  border: none;
  border-radius: 3px;
}

.dropdown_btn i {
  color: #477bf9;
}

.header-profile-user {
  height: 36px;
  width: 36px;
}

.footer {
  background-color: #fff;
  color: #98a6ad;
  left: 260px;
  padding: 20px 0.75rem;
  position: absolute;
  right: 0;
  bottom: 0;
}

.min-height-75 {
  min-height: 75vh;
}

.apexcharts-toolbar,
.apexcharts-legend {
  display: none !important;
}

.btn-soft-info {
  background-color: rgba(80, 176, 243, 0.1);
  font-size: 12px;
  color: #489edb;
}

.btn-soft-info:hover,
.btn-soft-info:active {
  background-color: #4496cf !important;
  color: #fff !important;
}

.btn-info {
  background-color: #4496cf;
  font-size: 12px;
  color: #fff !important;
}

.btn-info:hover,
.btn-info:active {
  background-color: #4496cf !important;
  color: #fff !important;
}

.sales-history,
.structure-list {
  padding: 10px 0;
}

.bg_primary {
  background-color: var(--primary-color) !important;
}

/* .dashboard_input input,
select {
  color: #0f0f12 !important;
  background-color: #ffffff !important;
  font-size: 15px !important;
  padding: 8px 14px !important;
  border: 1px solid #e9ebec !important;
}

.dashboard_input input:focus,
select:focus {
  border: 1px solid var(--primary-color) !important;
} */

.invoice_para p {
  margin-top: 20px;
}

.btn-light {
  background-color: #f5f5f5;
  color: #0f0f12 !important;
}

.avatar-sm {
  height: 2.6rem;
  width: 2.6rem;
}

.avatar-title {
  background-color: #438a7a;
  color: #fff;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-success-subtle {
  background-color: #e9dcef !important;
}

.nav-item a {
  height: 47px !important;
}

@media (max-width: 599px) {
  .footer {
    left: 0 !important;
    position: relative !important;
    bottom: -30px !important;
  }
}

.bg-light-subtle {
  background-color: #f5f5f52e !important;
}

.notification_menu {
  height: 330px;
  overflow: auto;
}

.search_input input {
  box-shadow: none !important;
  outline: none !important;
  background-color: #f5f5f5 !important;
  color: #0f0f12;
  border: none !important;
  min-height: 40px;
  font-size: 16px !important;
}

.search_input input {
  padding: 0px 0px 0px 32px !important;
  width: 40% !important;
}

.search_input_table input {
  padding: 4px 20px 4px 38px !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: #fff !important;
  color: #0f0f12;
  border: none !important;
  min-height: 40px;
  font-size: 16px !important;
}

.search_input_table input:focus {
  border: 1px solid var(--primary-color) !important;
}

@media (max-width: 992px) {
  .search_input input {
    width: 60% !important;
  }
}

@media (max-width: 599px) {
  .search_input input {
    width: 100% !important;
  }
}

.mui-without-bg-custom-table {
  background-color: #000;
  border: 1px solid #fff;
  padding-bottom: 20px;
}

.mui-without-bg-custom-table .MuiPaper-root,
.mui-without-bg-custom-table .Mui-selected,
.mui-without-bg-custom-table .MuiTableRow-hover:hover {
  background-color: #000;
}

.mui-without-bg-custom-table .MuiTablePagination-root p {
  margin-bottom: 0;
}

.mui-without-bg-custom-table .MuiCheckbox-root {
  padding: 0;
}

.mui-without-bg-custom-table .MuiPaper-elevation {
  background-color: transparent;
}

.mui-without-bg-custom-table .MuiOutlinedInput-root {
  border: 1px solid #8f8f8f;
  min-height: 45px !important;
}

.mui-without-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}

.mui-without-bg-custom-table .mui-table-footer .rows_selected_text {
  color: var(--portal-theme-primary);
  padding-left: 20px;
  padding-top: 15px;
  font-size: 1rem;
  font-weight: 400;
}

.table_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.table_avatar svg {
  color: gray !important;
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.btn-danger {
  background-color: #dc3545;
  color: var(--button-text-color) !important;
  font-size: 15.008px !important;
  line-height: 22.512px !important;
  padding: 8px 14.4px !important;
}

.sidebar-icons {
  margin-right: 10px !important;
  margin-top: -5px;
}

.sidebar-icons svg {
  font-size: 22px !important;
}

.sidebar-icons span {
  font-size: 15px !important;
}

.form-control:disabled {
  opacity: 1;
  color: #000000ab !important;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
} */

.bg-success-subtle {
  background-color: #e9dcef !important;
}

.text-success {
  --in-text-opacity: 1;
  color: rgba(147, 79, 176, var(--in-text-opacity)) !important;
}

.MuiOutlinedInput-root {
  height: 40px !important;
  min-height: 40px !important;
  background-color: #fff;
}

.auto-height .MuiOutlinedInput-root {
  height: auto !important;
  min-height: 40px !important;
  background-color: #fff;
  padding: 5px 5px 5px 5px !important;
}

.css-1fhlvfn-MuiFormLabel-root-MuiInputLabel-root {
  top: -6px !important;
}

.css-ys9lu4 {
  top: -6px !important;
}

.textarea-autosize:focus-visible,
.new-textarea-autosize:focus-visible {
  border: 2px solid var(--primary-color) !important;
}

.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  padding: 0.5px 0px 0.5px 5px !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0.5px 0px 0.5px 5px !important;
}

.input_top_text {
  font-size: 13px !important;
  font-weight: 600;
  color: #0f0f12;
}

@media (max-width: 600px) {
  .css-1qryw0r {
    width: 100% !important;
  }
}

.dashboard_table .css-dac6fd-MuiTableContainer-root {
  overflow-x: unset !important;
}

.dashboard_table .css-14ad8xz {
  overflow-x: unset !important;
}

.user_profile_img img {
  height: 110px;
  width: 110px;
  border-radius: 50%;
}

.css-10hburv-MuiTypography-root {
  font-size: 14px !important;
}

.btn-soft-primary {
  background-color: rgba(67, 138, 122, 0.1);
  font-size: 12px;
  color: var(--primary-color);
}

.btn-soft-primary:hover,
.btn-soft-primary:active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.btn-soft-danger {
  background-color: rgba(237, 67, 67, 0.1);
  font-size: 12px;
  color: #d53c3c;
}

.btn-soft-danger:hover,
.btn-soft-danger:active {
  background-color: #d53c3c !important;
  font-size: 12px;
  color: #fff !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 15px !important;
}
.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.css-1yycw6v {
  font-size: 15px !important;
}

.css-165mck1 {
  width: 100% !important;
}

.dropzone {
  background: #fff;
  border: 2px dashed #e9ebec;
  border-radius: 6px;
  min-height: 230px;
}

.dropzone label {
  cursor: pointer;
}

.dropzone .dz-message {
  font-size: 24px !important;
  margin: 1em 0;
  width: 100%;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

.file_input_name {
  box-shadow: none;
  border: 1px solid #00000038;
}

.actvie_crumbs {
  color: var(--primary-color) !important;
  /* font-weight: 600 !important; */
}

.alert_danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
  padding: 7px 10px;
}

.alert_danger i {
  cursor: pointer;
}

input:-webkit-autofill {
  background-color: transparent !important;
}

.special-label {
  display: none;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 40px !important;
  font-size: 16px !important;
}

.react-tel-input .form-control:focus {
  outline: 0;
  box-shadow: none !important;
  border: 2px solid #438a7a !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

.react-tel-input .country-list {
  width: 270px !important;
}

.react-tel-input .country-list .search-box {
  margin-left: -3px !important;
  height: 30px !important;
  width: 100% !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

input:-webkit-autofill {
  background-color: transparent !important;
}

/* edit profile screen  */
.profile-cards {
  border: none;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  cursor: pointer;
  transition: all 0.5s;
}

.cards-edit {
  border: none;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  transition: all 0.5s;
}

.edit-profile-icon-position {
  position: absolute !important;
  right: -10px;
  top: 66px;
}

.edit-profile-icon img {
  border-radius: 50px;
}

.edit-profile-icon {
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.profile_avatar svg {
  color: #00000070 !important;
}

.succeeded_bg {
  color: #046307 !important;
  fill: #046307 !important;
  background: #0463073d !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}
.payment_failed_bg {
  color: #c62828 !important;
  fill: #c62828 !important;
  background: #d32f2f29 !important;
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
}
.Initiated_bg {
  color: #fff !important;
  fill: #fff !important;
  background: #808080d9 !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}
.refunded_bg {
  color: #117e99 !important;
  fill: #117e99 !important;
  background: #117e9933 !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}
.canceled_bg {
  color: #e4d612 !important;
  fill: #e4d612 !important;
  background: #e4d6122d !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}
.pending_bg {
  color: rgb(156, 39, 176) !important;
  fill: rgb(156, 39, 176) !important;
  background: rgba(155, 39, 176, 0.178) !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}








.succeeded_bg_color {
  color: #046307 !important;
  fill: #046307 !important;
}
.payment_failed_bg_color {
  color: #c62828 !important;
  fill: #c62828 !important;
}

.Initiated_bg_color {
  color: #939393 !important;
  fill: #939393 !important;
}
.refunded_bg_color {
  color: #117e99 !important;
  fill: #117e99 !important;
}
.canceled_bg_color {
  color: #e4d612 !important;
  fill: #e4d612 !important;
}
.pending_bg_color {
  color: rgb(156, 39, 176) !important;
  fill: rgb(156, 39, 176) !important;
}


.tox-statusbar__branding {
  display: none !important;
}

.div-style {
  border-bottom: 1px solid #cacaca;
  padding: 8px;
}

.btn-primary svg {
  color: #fff !important;
}

.helper_text {
  color: black !important;
  font-size: 0.75rem !important;
}

.fixed_header {
  position: fixed;
  z-index: 999;
  width: 350px;
}

@media (max-width: 400px) {
  .fixed_header {
    width: 250px;
  }
}

.fixed_button {
  bottom: 30px;
  position: fixed;
  right: 20px;
  z-index: 1;
}

.pending-ticket {
  background-color: #ffc107;
  color: white !important;
  width: 80px;
}

.answer-ticket {
  background-color: #007bff;
  color: white !important;
  width: 80px;
}

.solved-ticket {
  background-color: #28a745;
  color: white !important;
  width: 80px;
}

.trash-ticket {
  background-color: #ff0000;
  color: white !important;
  width: 80px;
}

.sidebar-search-input {
  width: 100%;
  border: solid 1px var(--primary-color);
  height: 35px;
  font-size: 14px;
  border-radius: 5px;
  background-color: transparent;
  padding-left: 35px;
  color: var(--text-primary-color);
  outline: none !important;
}

.sidebar-search-box {
  position: relative;
}

.sidebar-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 16px;
}

.upload-button label {
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 76px;
  width: 76px;
  justify-content: center;
  padding: 15px;
  text-align: center !important;
}

.upload-button svg {
  color: #fff !important;
}

.preview {
  position: relative;
}

.preview img {
  border-radius: 5px;
  display: inline;
  height: 76px;
  margin-right: 10px;
  width: 65px;
}

.preview span {
  position: absolute;
  right: 3px;
  top: -32px;
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  z-index: 1;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

/* scrollbar */

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #438a7994;
}

::-webkit-scrollbar-thumb:hover {
  background: #438a7994;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:active {
  background: #666;
}

/* scrollbar */

.user_profile .profile_img {
  width: 100px;
  height: 100px;
  border-radius: 15px;
}

.del_img_icon {
  position: relative;
  display: inline-block;
}

.del_img_icon span {
  position: absolute;
  right: -6px;
  top: -4px;
  font-size: 10px;
  font-weight: 500;
  height: 15px;
  width: 15px;
  background-color: red;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  z-index: 1;
}

.del_img_icon img {
  height: 50px !important;
  border: 1px solid #438a7a;
}

.carousel-inner h5,
.carousel-inner p {
  color: #fff !important;
}

.profile-card h6 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.57;
}
.profile-card h3 {
  font-size: 20px;
}

.fw-14 {
  font-size: 14px !important;
}

.client_profile h6 {
  font-weight: 600 !important;
  margin-bottom: 0;
  font-size: 14px;
  margin-top: 2px;
}

.text_muted {
  opacity: 0.8;
  font-size: 14px;
}

#notistack-snackbar svg {
  color: #fff !important;
}

.m_top {
  margin-top: 2px;
}

.menu-background svg {
  cursor: pointer;
  font-size: 25px;
  background-color: #477bf91a;
  padding: 3px;
  border-radius: 3px;
}

.menu-background svg:hover {
  background-color: #4496cf !important;
  color: #fff !important;
}

.gold_border {
  border: 1px solid goldenrod;
  border-radius: 10px;
}

.pagination-style {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

@media (max-width: 1083px) {
  .pagination-style {
    margin-top: 10px;
  }
}

.filter_btn {
  position: absolute;
  bottom: 20px;
}

.btn-danger {
  min-width: 124px !important;
}

.clearIcon-button {
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  position: absolute;
  text-align: center;
  width: 20px;
  z-index: 1;
  border-radius: 10px;
}
.clearIcon-size {
  background-color: red !important;
  color: rgb(255, 255, 255) !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  padding: 2px 6px;
  position: absolute;
  top: -10px;
  cursor: pointer;
  font-weight: 600;
}
.navItems_search {
  margin-top: -20px;
}
.custom-textfield {
  position: relative;
  margin-bottom: 16px;
}

.label {
  position: absolute;
  top: -8px;
  left: 8px;
  background-color: white;
  padding: 0 4px;
  font-size: 12px;
  color: #000000 !important;
}

.title {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  width: 105%;
  min-height: 40px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.check-box-access {
  padding-left: 45px;
}
.css-fpqqwi-MuiCardContent-root:last-child {
  padding-bottom: 0px;
}

/* TagsInput */

.react-tagsinput {
  border-radius: 5px;
}

.react-tagsinput--focused {
  border: 2px solid #438a7a !important;
}

.react-tagsinput-tag {
  background-color: #438a7aa3 !important;
  color: #fff !important;
  border-radius: 5px;
  border: 1px solid #438a7a !important;
}
.react-tagsinput-remove {
  color: #fff !important;
}

.preview-link {
  cursor: pointer;
}

.preview-link:hover {
  color: #438a7a !important;
}

.preview_list img {
  max-width: 100% !important;
}

.MuiTableCell-body {
  white-space: nowrap !important;
}

.scroll-top-community {
  position: fixed;
  bottom: -80px;
  font-size: 14px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 2px;
  right: 110px;
  cursor: pointer;
  z-index: 9999;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 1s ease;
}

.scroll-top-community i {
  color: #fff;
}

.ticket_card {
  background-color: #f9f9f996;
  padding: 10px;
  margin: 1px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
}

.ticket_card span {
  font-size: 14px;
}

.ticket_card_img img {
  width: 76px;
  padding: 10px;
}

.internal_card {
  background-color: #f9f9f996;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
}

.internal_card span {
  font-size: 14px;
}

.internal_card img {
  width: 76px;
}

.preview_card img {
  width: 76px;
}

.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.no-access-string img {
  width: 120px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.upload-button-nav-items label {
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  /* height: 76px;
  width: 76px; */
  height: 28px;
  width: 60px;
  justify-content: center;
  padding: 2px 30px;
  text-align: center !important;
}

.upload-button-nav-items svg {
  color: #fff !important;
}

.preview-nav-items {
  position: relative;
}

.preview-nav-items img {
  border-radius: 5px;
  display: inline;
  height: 30px;
  margin-right: 10px;
  width: 30px;
}

.preview-nav-items span {
  position: absolute;
  right: 3px;
  top: -21px;
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  z-index: 1;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
.search_btn {
  height: 40px !important;
  margin-top: 2px !important;
}
 
.popover-close-icon{
  background-color: red;
  border-radius: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  height: 18px;
  position: absolute;
  right: 8px;
  text-align: center;
  top: 7px;
  width: 18px;
  z-index: 1;
}
.clear-all-button {
  cursor: pointer;        
  color: #666666;          
  font-size: 16px;   
  font-weight: 500;              
  text-decoration: underline;   
  padding: 4px 8px;             
  border-radius: 4px;          
  transition: background-color 0.3s ease, color 0.3s ease;  
}

.clear-all-button:hover {
  color: #333333;              
  background-color: #f0f0f0;    
}

.label_text{
  position: absolute;
  top: -8px;
  left: 10px;
  background-color: transparent;
  padding: 0px 5px;
  z-index: 2;
  font-weight: 600;  
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}
.label_bg{
  position: absolute;
  top: -1px;
  left: 10px;
  background-color: white;
  padding: 0px 5px;
  font-size: 10px !important;
  color: transparent;
  z-index: 1;
  font-weight: 600;
  height: 3px !important;
}

.filter-cross-icon svg {
  height: 15px !important;
  width: 15px !important;
}


/* Chat CSS Start*/

 

.left-side{
  background-color: #f1f1f1;
  border-top-left-radius: 0 !important;
  font-size: 14px;
 
}

.right-side{
  background-color: #ccf7ff;
  border-bottom-right-radius: 0 !important;
}

.width-80 {
  max-width: 80%!important;
}

.message-text{
  font-size: 14px!important;
}
.message-time{
  font-size: 12px!important;
  opacity: 0.5!important;
}
.icon-check{
 
  color: #007bff!important
}
.text-light-show , .text-light-show p {
  font-size: 12px!important;
  opacity: 0.7!important;
}
.text-unread-show{
  font-size: 10px!important;
  opacity: 0.7!important;
}
.text-unread-bg{
  background-color: #438a7a;
}
.chat-profile-active{
  background-color: #438a7a0f;
  border-radius: 5px;
}
.chat-profile:hover{
  background-color: #438a7a10;
}

.chat-profile:active{
  background-color: #438a7a1f;
}

.all-messages {
  border: 1px solid #595b5c;
  border-radius: 15px;
  cursor: pointer;
  margin: auto;
  padding: 3px 15px;
  color:#595b5c;
}
.selected-messages {
  background-color: #e8f1ef;
  border: 1px solid #438a7a;
  color: #438a7a;
}

.flex-end{
  display: flex!important;
  justify-content: flex-end!important;
}


.textarea-custom {
  padding: 8px 12px; /* Same padding as TextField */
  font-size: 14px;
  line-height: 1.4375;
  border: 1px solid rgba(0, 0, 0, 0.23); /* Match TextField border */
  border-radius: 4px; /* Match TextField border radius */
  resize: none; /* Prevent resizing */
  box-sizing: border-box;
  width: 100%;
}

.textarea-custom:focus {
  outline: none;
  border-color: #438a7a; /* TextField focus color */
  box-shadow: 0 0 0 2px #438a7a0f; /* Focus shadow */
}
.before-send-image{
  opacity: 0.8;
  border-radius: 5px !important;
  /* margin-left: 68px; */
  margin: 10px;
}

.chat-image-remove-cross-button{
  color: #fff;
}
.chat-add-contact svg{
  color: #fff !important;
}
.chat-menu .menu-background svg {
  cursor: pointer;
    font-size: 25px;
    /* background-color: #ccf7ff; */
    background-color: transparent!important; 
    padding: 3px;
    border-radius: 3px;
 
    transform: rotate(90deg); 

}

.chat-menu .menu-background svg:hover {
  /* background-color: #438a7a !important; */
  background-color: transparent!important; 
  color: #fff !important;
  color: #438a7a !important;
}

.message-row .chat-menu {
  display: none!important;  
}

.message-row:hover .chat-menu {
  display: block!important;  
}

.message-row .chat-menu-hide {
  display: block!important;   
 
}

.message-row:hover .chat-menu-hide {
  display: none!important;  
}

.textarea-no-border {
  margin: 0 !important;
  outline: none !important;
  border: none;
  box-shadow: none;
}

.textarea-no-border:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}
.text-start a{
  font-weight: 600!important;
}
.text-start a:hover{
  text-decoration: underline!important;
}
/* Chat CSS End */
.simplebar-placeholder{
  height: auto !important;
}
